













import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'AccountIndex',
    components: {
        VsContainer,
        VsTabs,
    },
})
export default class extends Vue {
    get user () {
        return UserModule.user
    }

    get activeTab () {
        return this.$route.name
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('account.settings.tabs.myData'),
                id: 'myAccount',
                to: {
                    name: 'myAccount',
                },
            },
            {
                label: this.$t('account.settings.tabs.security'),
                id: 'security',
                to: {
                    name: 'security',
                },
            },
            {
                label: this.$t('account.settings.tabs.consents'),
                id: 'consents',
                to: {
                    name: 'consents',
                },
            },
        ]

        // if (this.is4Dem) {
        //     tabs.push({
        //         label: this.$t('account.settings.tabs.comunications'),
        //         id: 'communication',
        //         to: {
        //             name: 'communication',
        //         },
        //     })
        // }

        return tabs
    }
}
